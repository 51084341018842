import "../scss/headline.scss"
import $ from 'jquery'
import 'jquery-match-height'

$(function(){

$('main').find(`h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6`)
    .matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });
})
