import "../scss/_styles.scss"
import $ from "jquery"

class BlitzMap {

  constructor(elem) {
    this.$elem = $(elem)
    this._bind_open_events()
    this._bind_close_event()
  }

  _bind_close_event = () => {
    $('body').on('click', this._close_overlays)
  }

  _close_overlays = () => {
    this.$elem.find('.blitz-map-overlay').removeClass("active")
    this.$elem.removeClass("open-overlay")
  }

  _bind_open_events = () => {
    const triggers = this.$elem.find('svg g[id^="xx_"]')
    triggers.each(
      (i, e) => {
        const $e = $(e),
          overlay = this.$elem.find(`.blitz-map-overlay[data-selector="${$e.attr("id")}"]`)

        $(e).on("click", (e) => {
          overlay.addClass("active")
          this.$elem.addClass("open-overlay")
          e.stopPropagation()
        })
        $(overlay).find("button.close").on("click", this._close_overlays)
        overlay.on('click', (e) => {
          e.stopPropagation()
        });
      },
    )
  }
}

$(() => {
  $(".blitz-map").each(
    (i, e) => {
      new BlitzMap(e)
    },
  )
})
