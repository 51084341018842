import $ from 'jquery'
import "./page_background.scss"
import image from "../../images/pattern_vorlage_smooth.png"
import backgroundShaderVert from "./shader.vert"
import backgroundShaderFrag from "./shader.frag"


$(function() {

    const shaderParams = {
      triangleScale: 0.5,
      colorScale: 0.3,
      colorTimeScale: 0.4,
      rotation: 1.3,
    };

    // for debugging...
    window.shaderParams = shaderParams;

    let
      startTime = new Date().getTime(),
      lastShaderTime = startTime,
      globalMouseX = 300,  // initial value to start moving right on load
      globalMouseY = 100,
      mouseAgent = {
        x: 0.,
        y: 0.,
        velX: 0.,
        velY: 0.,
      };

    function createGLContext(canvas) {
      let names = ["webgl", "experimental-webgl"];
      let context = null;
      for (let i = 0; i < names.length; i++) {
        try {
          context = canvas.getContext(names[i]);
        } catch (e) {
        }
        if (context) {
          break;
        }
      }
      if (context) {
        context.viewportWidth = canvas.width;
        context.viewportHeight = canvas.height;
      } else {
        console.log("Failed to create WebGL context!");
        return null;
      }
      return context;
    }

    function compileShader(ctx, shaderType, shaderSource) {
      const glShaderType = shaderType === "VERTEX"
        ? ctx.gl.VERTEX_SHADER
        : ctx.gl.FRAGMENT_SHADER;
      let shader = ctx.gl.createShader(glShaderType);

      ctx.gl.shaderSource(shader, shaderSource);
      ctx.gl.compileShader(shader);

      if (!ctx.gl.getShaderParameter(shader, ctx.gl.COMPILE_STATUS)) {
        console.log("shader compilation failed", shaderType, ctx.gl.getShaderInfoLog(shader));
        // console.log(shaderSource);
        return null;
      }
      return shader;
    }

    function setupShaders(ctx) {
      ctx.vertexShader = compileShader(ctx, "VERTEX", backgroundShaderVert);
      ctx.fragmentShader = compileShader(ctx, "FRAGMENT", backgroundShaderFrag);
      if (!ctx.vertexShader || !ctx.fragmentShader)
        return;

      ctx.shaderProgram = ctx.gl.createProgram();
      ctx.gl.attachShader(ctx.shaderProgram, ctx.vertexShader);
      ctx.gl.attachShader(ctx.shaderProgram, ctx.fragmentShader);
      ctx.gl.linkProgram(ctx.shaderProgram);
      if (!ctx.gl.getProgramParameter(ctx.shaderProgram, ctx.gl.LINK_STATUS)) {
        console.log("Failed to setup shaders");
        return;
      }
      ctx.gl.useProgram(ctx.shaderProgram);

      ctx.uniformLocation = {
        resolution: ctx.gl.getUniformLocation(ctx.shaderProgram, "uResolution"),
        time: ctx.gl.getUniformLocation(ctx.shaderProgram, "uTime"),
        scale: ctx.gl.getUniformLocation(ctx.shaderProgram, "uScale"),
        colorScale: ctx.gl.getUniformLocation(ctx.shaderProgram, "uColorScale"),
        colorTimeScale: ctx.gl.getUniformLocation(ctx.shaderProgram, "uColorTimeScale"),
        triangleScale: ctx.gl.getUniformLocation(ctx.shaderProgram, "uTriangleScale"),
        mouse: ctx.gl.getUniformLocation(ctx.shaderProgram, "uMouse"),
        topPosition: ctx.gl.getUniformLocation(ctx.shaderProgram, "uTopPosition"),
        rotation: ctx.gl.getUniformLocation(ctx.shaderProgram, "uRotation"),
      };

      ctx.shaderProgram.vertexPositionAttribute = ctx.gl.getAttribLocation(ctx.shaderProgram, "aVertexPosition");

      ctx.shadersReady = true;
    }

    function setupBuffers(ctx) {
      ctx.vertexBuffer = ctx.gl.createBuffer();
      ctx.gl.bindBuffer(ctx.gl.ARRAY_BUFFER, ctx.vertexBuffer);
      let triangleVertices = [
        -1., -1., 0.0,
        1., -1., 0.0,
        1., 1., 0.0,

        -1., -1., 0.0,
        1., 1., 0.0,
        -1., 1., 0.0,
      ];
      ctx.gl.bufferData(ctx.gl.ARRAY_BUFFER, new Float32Array(triangleVertices), ctx.gl.STATIC_DRAW);
      ctx.vertexBuffer.itemSize = 3;
      ctx.vertexBuffer.numberOfItems = 6;
    }

    function setupTextures(ctx) {
      ctx.texture = ctx.gl.createTexture();
      ctx.gl.bindTexture(ctx.gl.TEXTURE_2D, ctx.texture);
      ctx.gl.texParameteri(ctx.gl.TEXTURE_2D, ctx.gl.TEXTURE_MIN_FILTER, ctx.gl.NEAREST);
      ctx.gl.texParameteri(ctx.gl.TEXTURE_2D, ctx.gl.TEXTURE_MAG_FILTER, ctx.gl.NEAREST);
      ctx.gl.texParameteri(ctx.gl.TEXTURE_2D, ctx.gl.TEXTURE_WRAP_S, ctx.gl.CLAMP_TO_EDGE);
      ctx.gl.texParameteri(ctx.gl.TEXTURE_2D, ctx.gl.TEXTURE_WRAP_T, ctx.gl.CLAMP_TO_EDGE);
    }

    function moveMouseAgent(agent, secondsPerFrame) {
      const
        deltaX = globalMouseX - agent.x,
        deltaY = globalMouseY - agent.y,
        manhattenDistance = Math.abs(deltaX) + Math.abs(deltaY),
        velFactor = Math.min(1., Math.max(-1.,
          secondsPerFrame / (1. + 0.004 * manhattenDistance)
        ))
      ;

      agent.velX = Math.max(-500, Math.min(500., agent.velX + deltaX * velFactor));
      agent.velY = Math.max(-500, Math.min(500., agent.velY + deltaY * velFactor));

      agent.x += agent.velX * velFactor;
      agent.y += agent.velY * velFactor;

      agent.velX -= agent.velX * velFactor * .97;
      agent.velY -= agent.velY * velFactor * .97;

      if (Math.abs(agent.velX) < 1e-4)
        agent.velX = 0.;
      if (Math.abs(agent.velY) < 1e-4)
        agent.velY = 0.;
    }

    function draw(ctx) {
      const
        currentTime = new Date().getTime(),
        secondsPerFrame = (currentTime - lastShaderTime) / 1000.;
      lastShaderTime = currentTime;

      moveMouseAgent(mouseAgent, secondsPerFrame);

      let gl = ctx.gl;

      let bb = ctx.canvas.getBoundingClientRect();
      const scaleFactor = 1.; //bb.width / ctx.canvas.width;
      gl.uniform2f(ctx.uniformLocation.topPosition, bb.left, -bb.top * .25);
      //gl.uniform2f(ctx.uniformLocation.resolution, ctx.canvas.width, ctx.canvas.height);
      gl.uniform2f(ctx.uniformLocation.resolution, bb.width, bb.height);
      gl.uniform1f(ctx.uniformLocation.time, (currentTime - startTime) / 1000.);
      gl.uniform2f(ctx.uniformLocation.scale, scaleFactor, scaleFactor);
      gl.uniform1f(ctx.uniformLocation.colorScale, shaderParams.colorScale);
      gl.uniform1f(ctx.uniformLocation.colorTimeScale, shaderParams.colorTimeScale);
      gl.uniform1f(ctx.uniformLocation.triangleScale, shaderParams.triangleScale);
      gl.uniform1f(ctx.uniformLocation.rotation, shaderParams.rotation);

      gl.uniform2f(
        ctx.uniformLocationMouse,
        mouseAgent.x - bb.left,
        bb.height - (mouseAgent.y - bb.top),
      );

      gl.viewport(0, 0, ctx.canvas.width, ctx.canvas.height);
      gl.clear(gl.COLOR_BUFFER_BIT);

      gl.vertexAttribPointer(ctx.shaderProgram.vertexPositionAttribute,
        ctx.vertexBuffer.itemSize, gl.FLOAT, false, 0, 0);
      gl.enableVertexAttribArray(ctx.shaderProgram.vertexPositionAttribute);

      gl.drawArrays(gl.TRIANGLES, 0, ctx.vertexBuffer.numberOfItems);

      setTimeout(function () {
        draw(ctx)
      }, 1. / 30.);
    }

    function loadImage(ctx, src) {
      let image = new Image();
      image.addEventListener('load', function () {
        try {
          ctx.gl.bindTexture(ctx.gl.TEXTURE_2D, ctx.texture);
          ctx.gl.texImage2D(ctx.gl.TEXTURE_2D, 0, ctx.gl.RGBA, ctx.gl.RGBA, ctx.gl.UNSIGNED_BYTE, image);
        } catch (e) {
          console.log('shader error', e)
          $('.page-background').removeClass('initialized')
        }
        //ctx.gl.generateMipmap(ctx.gl.TEXTURE_2D);
      });
      image.src = src;
    }

    function startup(canvas) {
      let ctx = {};
      ctx.canvas = canvas;
      ctx.gl = createGLContext(ctx.canvas);
      if (ctx.gl) {
        setupShaders(ctx);
        if (ctx.shadersReady) {
          setupBuffers(ctx);
          setupTextures(ctx);
          loadImage(ctx, image);
          ctx.gl.clearColor(0.0, 0.0, 0.0, 1.0);
          draw(ctx);
        }
        return ctx;
      }
    }

    function startupAll() {
      let canvases = document.querySelectorAll(".page-background");
      for (let i = 0; i < canvases.length; ++i) {
        let canvas = canvases[i];
        let bb = canvas.getBoundingClientRect();
        canvas.width = bb.width;
        canvas.height = bb.height;
        startup(canvas);
      }
      //startup(document.getElementById('canvas-2'));

      document.addEventListener("mousemove", function (e) {
        globalMouseX = e.clientX;
        globalMouseY = e.clientY;
        //console.log("mouse", globalMouseX, globalMouseY);
        //console.log("agent", mouseAgent.x, mouseAgent.y);
        //console.log("agent-vel", mouseAgent.velX, mouseAgent.velY);
      });
    }

    try {
      startupAll();
      $('.page-background').addClass('initialized');
    } catch (e) {
    $('.page-background').removeClass('initialized');
      console.error('shader not initialized', e);
    }
  }
);
