import $ from 'jquery'
import { tns } from "tiny-slider/src/tiny-slider"

$(function(){
    const slider_container = document.querySelector('.sub-navigation ul')
    if (slider_container) {
        const slider = tns({
            center: false,
            container: slider_container,
            autoplay: false,
            mouseDrag: true,
            autoWidth: true,
            freezable: true,
            // autoHeight: true,
            loop: false,
            controlsText: ["", ""],
            // gutter: 10,
            slideBy: 1,
            responsive: {
                350: {
                    items: 2,
                },
                500: {
                    items: 3,
                }
            },
        })
    }
});
