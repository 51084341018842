import "../scss/main.scss"

import $ from 'jquery'


import Foundation from "foundation-sites"

//import lazy-image and init
import 'frameworks/lazy_image_init'

// get the background moving
import "page_background"

// plugins
import "blog_categories/js"
import "blitz_map/js"
import "buttons/js"
import "cmsplugin_contact_plus/js"
import "column_control/js"
import "downloader/js"
import "footer/js"
import "grid_image_gallery/js"
import "headline/js"
import "image/js"
import "image_gallery/js"
import "navigation/js"
import "opening_hours/js"
import "section/js"
import "sponsors/js"
import "stage/js"
import "team/js"
import "video_player/js"

import "subnavigation"

$(() => {
    $(document).foundation()
})
