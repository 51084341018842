import "../scss/navigation.scss"

import $ from 'jquery'

class MainNavigation {
    constructor() {
        this.mark_active_menu()
        this.attach_expand_event()
    }

    attach_expand_event = () => {
        const $menu = $('.main-navi nav.main'),
            $trigger = $('.main-navi button.expand-navigation:not([data-toggle])')

        $trigger.on('click', function(){
            if ($menu.hasClass('menu-expanded'))
            {
                $menu.slideUp("fast", function() {
                })
                $menu.removeClass('menu-expanded')
                $trigger.removeClass('menu-expanded')
            } else {
                $menu.slideDown("fast", function() {
                })
                $trigger.addClass('menu-expanded')
                $menu.addClass('menu-expanded')
            }
        })
    }

    mark_active_menu = () => {
        const current_url = window.location.pathname
        const url_segements = current_url.split('/')
        let anchor = false

        for (var i=url_segements.length-1; i>2; i--) {
            url_segements.length = i
            anchor = document.querySelector(`nav.main a[href|="${url_segements.join('/')}/"]`)
            if (anchor) break
        }

        if (anchor) {
            const list = anchor.closest('[data-accordion-item]')
            if (list) {
                list.className += ' is-active'
            } else {
                const closest_list = anchor.closest('li')
                closest_list.className += ' is-active'
            }
            anchor.className += ' is-active'
        }
    }
}

$(() => {
    const mainNavigation = new MainNavigation()
})
