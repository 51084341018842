import "../scss/styles.scss"
import $ from 'jquery'

import Viewer from 'viewerjs'

$(()=>{
    $('.grid-image-gallery').each(function() {

        const fancy = new Viewer(
            this, {
                title: (image, image_data) => {
                    const data = fancy.images[fancy.index].dataset
                    if (data['title']) return data['title']
                    return ""
                },
                url: (image, image_data) => {
                    const data = image.dataset;
                    return data['fullSrc']
                },
                tooltip: false,
                navbar: false,
                zoomOnWheel: false,
                toggleOnDblclick: false,
                zoomable: false,
                movable: false,
                toolbar: {
                    zoomIn: false,
                    zoomOut: false,
                    oneToOne: false,
                    reset: false,
                    prev: 1,
                    play: {
                        show: false,
                        size: 'large',
                    },
                    next: 1,
                    rotateLeft: false,
                    rotateRight: false,
                    flipHorizontal: false,
                    flipVertical: false,
                }

            }

        );
    })
})
