import "../scss/image_gallery.scss"
import { tns } from "tiny-slider/src/tiny-slider"
import $ from 'jquery'

import Viewer from 'viewerjs'

$(()=>{
    $('.image-gallery .slider').each(function() {
        const slider = tns({
            "container": this,
            "autoHeight": true,
            "items": 1, 
            "autoplay": true,
            "autoplayHoverPause": true,
            "autoplayTimeout": 3500,
            "autoplayText": [
              "",
              ""
            ],
            "swipeAngle": false,
            "speed": 400,
            "controlsText": ["",""]
        });

        const fancy = new Viewer(
            this, {
                title: (image, image_data) => {
                    const data = fancy.images[fancy.index].dataset
                    if (data['title']) return data['title']
                    return ""
                },
                url: (image, image_data) => {
                    const data = image.dataset;
                    return data['src']
                },
                tooltip: false,
                navbar: false,
                toolbar: {
                    zoomIn: false,
                    zoomOut: false,
                    oneToOne: false,
                    reset: false,
                    prev: 4,
                    play: {
                        show: false,
                        size: 'large',
                    },
                    next: 4,
                    rotateLeft: false,
                    rotateRight: false,
                    flipHorizontal: false,
                    flipVertical: false,
                }

            }

        );
    })
})
