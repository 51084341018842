import "../scss/downloader.scss"
import $ from "jquery"

$(document).ready(function () {


    $("[id^=show-more-description]").click(function () {
        let elementId=$(this).attr('id').split("show-more-description-")[1]
        $("#more-description-"+elementId).show()
        $("#show-less-description-"+elementId).show()
        $(this).hide()
    })

    $("[id^=show-less-description]").click(function () {
        let elementId=$(this).attr('id').split("show-less-description-")[1]
        $("#more-description-"+elementId).hide()
        $(this).hide()
        $("#show-more-description-"+elementId).show()
    })
})